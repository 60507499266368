// eslint-disable-next-line max-classes-per-file
import { AdvancedFilterGeneric } from '~/app/shared/types/advanced-filter/advanced-filter-generic.type';
import { AdvancedFilterValue } from '~/app/shared/types/advanced-filter/advanced-filter-value.type';
import { SortRequestValue } from '~/app/shared/types/sort-request-value.type';

export class ClearContactsAction {
    static readonly type = '[ContactsList] Clear contacts';

    constructor(public contactsIds: Array<number> = []) {}
}

export class CloseContactsListAction {
    static readonly type = '[ContactsList] Close contacts list';

    constructor(public key: string) {}
}

export class ChangeSelectionAction {
    static readonly type = '[ContactsList] Change selection';

    constructor(public contactId: number) {}
}

export class AddContactsListAction {
    static readonly type = '[ContactsList] Add new contacts list';

    constructor(public key: string, public overrideList = false, public extraFilters: AdvancedFilterGeneric[] = []) {}
}

export class AddContactsToGroupsAction {
    static readonly type = '[ContactsList] Add contacts to groups';

    constructor(public groupsIds: number[], public contactsIds: number[], public clearSelected = true, public refreshGroups = true) {}
}

export class ApplySortValueAction {
    static readonly type = '[ContactsList] Apply sort value';

    constructor(public sortValue: SortRequestValue[]) {}
}

export class ChangeCurrentContactsListAction {
    static readonly type = '[ContactsList] Change current contacts list';

    constructor(public key: string) {}
}

export class ChangeCurrentGroupAction {
    static readonly type = '[ContactsList] Change current group';

    constructor(public id: number) {}
}

export class ChangeSubSegmentationAction {
    static readonly type = '[ContactsList] Change current sub segmentation';

    constructor(public subSegmentationId: number) {}
}

export class CreateGroupAction {
    static readonly type = '[ContactsList] Create group';

    constructor(
        public name: string,
        public contactsIds: Array<number>,
        public mustStay: boolean = false,
        public emptySelected: boolean = false,
    ) {}
}

export class DeleteContactsAction {
    static readonly type = '[ContactsList] Delete contacts';

    constructor(public contactIds: number[]) {}
}

export class DeleteGroupAction {
    static readonly type = '[ContactsList] Delete group';

    constructor(public groupId: number) {}
}

export class GetGroupsAction {
    static readonly type = '[ContactsList] Get groups';
}

export class GetGroupAction {
    static readonly type = '[ContactsList] Get a group';

    constructor(public groupId: number) {}
}

export class GetMetaDataAction {
    static readonly type = '[ContactsList] Retrieve the contacts metaData';

    constructor(public refreshCache: boolean = false) {}
}

export class ResetAndSearchAction {
    static readonly type = '[ContactsList] Reset and search';
}

export class RemoveContactsFromGroupsAction {
    static readonly type = '[ContactsList] Remove contacts from groups';

    constructor(public groupsIds: number[], public contactsIds: number[], public clearSelected = true, public refreshGroups = true) {}
}

export class SearchAction {
    static readonly type = '[ContactsList] Search contacts';
}

export class SearchTextAction {
    static readonly type = '[ContactsList] Search text';

    constructor(public query: string = '') {}
}

export class ClearSearchAction {
    static readonly type = '[ContactsList] Clear requestId';
}


export class SelectAllAction {
    static readonly type = '[ContactsList] Select all';
}

export class SelectUnselectContactAction {
    static readonly type = '[ContactsList] Select/Unselect contact by id';

    constructor(public id: number) {}
}

export class UpdateGroupAction {
    static readonly type = '[ContactsList] Update group';

    constructor(public id: number, public name: string) {}
}

export class UnselectAllAction {
    static readonly type = '[ContactsList] Unselect all';
}

export class SetFiltersAction {
    static readonly type = '[ContactsList] Set Filters';

    constructor(public filters: AdvancedFilterValue[]) {}
}

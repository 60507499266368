import { Injectable } from '@angular/core';
import {
    Action,
    Selector,
    State,
    StateContext,
    StateOperator,
} from '@ngxs/store';
import {
    indexBy,
    mergeRight,
    pick,
    prop,
} from 'ramda';
import {
    of,
    switchMap,
    tap,
} from 'rxjs';

import {
    CONTACTS_SEARCH_FIELDS,
    CONTACTS_SIZE_LIST,
    ENVESTBOARD_GROUP_ID,
} from '~/app/core/constants/contacts.constants';
import { DirectionType } from '~/app/shared/enums/direction-type.enum';
import { AdvancedFiltersUtilsService } from '~/app/shared/services/advanced-filters-utils/advanced-filters-utils.service';
import { AdvancedFilterGeneric } from '~/app/shared/types/advanced-filter/advanced-filter-generic.type';
import { AdvancedFilterValueGeneric } from '~/app/shared/types/advanced-filter/advanced-filter-value-generic.type';
import { CollectionOfContacts } from '~/app/shared/types/contacts/collection-of-contacts.type';
import { ContactSelected } from '~/app/shared/types/contacts/contact-selected';
import { ContactUniverse } from '~/app/shared/types/contacts/contact-universe.type';
import { Contact } from '~/app/shared/types/contacts/contact.type';
import { FacetFamily } from '~/app/shared/types/facet/facet-family.type';
import { ContactGroup } from '~/app/shared/types/search/contact-group.type';
import { SortRequestValue } from '~/app/shared/types/sort-request-value.type';
import { SubSegmentation } from '~/app/shared/types/sub-segmentation.type';

import {
    AddContactsListAction,
    AddContactsToGroupsAction,
    ApplySortValueAction,
    ChangeCurrentContactsListAction,
    ChangeCurrentGroupAction,
    ChangeSelectionAction,
    ChangeSubSegmentationAction,
    ClearContactsAction,
    ClearSearchAction,
    CreateGroupAction,
    DeleteContactsAction,
    DeleteGroupAction,
    GetGroupAction,
    GetGroupsAction,
    GetMetaDataAction,
    RemoveContactsFromGroupsAction,
    ResetAndSearchAction,
    SearchAction,
    SearchTextAction,
    SelectAllAction,
    SelectUnselectContactAction,
    SetFiltersAction,
    UnselectAllAction,
    UpdateGroupAction,
} from './contacts-list.actions';
import { ContactGroupsService } from '../../services/api/contact-groups/contact-groups.service';
import { ContactsService } from '../../services/api/contacts/contacts.service';

export interface ContactsListModel {
    isLoading: boolean,
    currentSubSegmentation: number,
    currentGroup: number,
    extraFilters: Array<AdvancedFilterGeneric>,
    filters: Array<AdvancedFilterGeneric>,
    filtersCount: number,
    query: string,
    contacts: Omit<CollectionOfContacts<number>, 'metadata'>,
    selected: ContactSelected[],
    sortValue: SortRequestValue[],
}

export interface ContactsListStateModel {
    currentList: string,
    facets: FacetFamily[],
    contacts: { [key: number]: Contact },
    lists: { [key: string]: ContactsListModel },
    subSegmentations: SubSegmentation[],
    groups: { [key: string]: ContactUniverse },
}

const defaults = {
    currentList: '',
    facets: [],
    contacts: {},
    lists: {},
    subSegmentations: [],
    groups: {},
};

function isLoading(): StateOperator<ContactsListStateModel> {
    return (state: Readonly<ContactsListStateModel>) => ({
        ...state,
        lists: {
            ...state.lists,
            [state.currentList]: {
                ...state.lists[state.currentList],
                isLoading: true,
            },
        },
    });
}

function updateCurrentList(update: { [key: string]: any }): StateOperator<ContactsListStateModel> {
    return (state: Readonly<ContactsListStateModel>) => ({
        ...state,
        lists: {
            ...state.lists,
            [state.currentList]: {
                ...state.lists[state.currentList],
                ...update,
            },
        },
    });
}

function searchQueries(
    limit: number | null,
    state: ContactsListStateModel,
    filters: AdvancedFilterValueGeneric[],
    fields: string,
    sorts: SortRequestValue[],
    includeMetadata: boolean,
) {
    const { currentGroup, currentSubSegmentation, contacts, query } = state.lists[state.currentList];
    const subSegmentation = state.subSegmentations.find((item) => item.id === currentSubSegmentation);

    return {
        fields,
        q: query,
        filters,
        sorts,
        group: currentGroup,
        subSegmentation: subSegmentation ? subSegmentation.code : 'ALL',
        includeMetadata,
        startAt: contacts.startAt,
        size: (limit && limit < CONTACTS_SIZE_LIST) ? limit : CONTACTS_SIZE_LIST,
        requestId: contacts.requestId,
    };
}

@State<ContactsListStateModel>({
    name: 'contactsList',
    defaults,
})
@Injectable()
export class ContactsListState {
    public constructor(
        private contactsService: ContactsService,
        private contactGroups: ContactGroupsService,
        private advancedFiltersUtilsService: AdvancedFiltersUtilsService,
    ) { }

    @Selector()
    static getContacts(state: ContactsListStateModel): Contact[] {
        return state.lists[state.currentList].contacts.values.map((r) => state.contacts[r]).filter((r) => r);
    }

    @Selector()
    static getCurrentGroup(state: ContactsListStateModel): ContactGroup {
        return state.groups[state.lists[state.currentList].currentGroup];
    }

    @Selector()
    static getCurrentGroupId(state: ContactsListStateModel): number {
        return state.lists[state.currentList].currentGroup;
    }

    @Selector()
    static getGroups(state: ContactsListStateModel): ContactGroup[] {
        return Object.values(state.groups);
    }

    @Selector()
    static getCurrentLimitSortValue(): number | null {
        return null;
    }

    @Selector()
    static getCurrentSubSegmentation(state: ContactsListStateModel) {
        return state.lists[state.currentList].currentSubSegmentation;
    }

    @Selector()
    static getQuery(state: ContactsListStateModel): string {
        return state.lists[state.currentList].query;
    }

    @Selector()
    static getRequestIdOfContacts(state: ContactsListStateModel) {
        return state.lists[state.currentList].contacts.requestId;
    }

    @Selector()
    static getSelectedContacts(state: ContactsListStateModel) {
        return state.lists[state.currentList].selected;
    }

    @Selector()
    static getSelectedContactsIds(state: ContactsListStateModel) {
        return state.lists[state.currentList].selected.map((selectedShare) => selectedShare.id);
    }

    @Selector()
    static getSelectedContactsCount(state: ContactsListStateModel) {
        return state.lists[state.currentList].selected.length;
    }

    @Selector()
    static getSortValue(state: ContactsListStateModel) {
        return state.lists[state.currentList].sortValue;
    }

    @Selector()
    static getSubSegmentations(state: ContactsListStateModel) {
        return Object.values(state.subSegmentations).map((item) => ({
            key: item.id,
            label: item.name,
            number: item.count,
        }));
    }

    @Selector()
    static isLoading(state: ContactsListStateModel) {
        return state.lists[state.currentList].isLoading;
    }

    @Selector()
    static isSearchDisabled(state: ContactsListStateModel) {
        const limit = this.getCurrentLimitSortValue();
        const isSearchLoading = this.isLoading(state);
        const contactsLength = state.lists[state.currentList].contacts.values.length;
        const { totalNumber } = state.lists[state.currentList].contacts;

        return contactsLength >= totalNumber || (limit && limit <= contactsLength) || isSearchLoading;
    }

    @Selector()
    static getFacets(state: ContactsListStateModel): FacetFamily[] {
        return state.facets;
    }

    @Selector()
    static getCurrentFilters(state: ContactsListStateModel): AdvancedFilterGeneric[] {
        return state.lists[state.currentList].filters;
    }

    @Selector()
    static getCurrentFiltersCount(state: ContactsListStateModel): number {
        return state.lists[state.currentList].filtersCount;
    }

    @Action(AddContactsListAction)
    addContactsList({ patchState, getState }: StateContext<ContactsListStateModel>, action: AddContactsListAction) {
        const state = getState();

        if (!state.lists[action.key] || action.overrideList) {
            patchState({
                currentList: action.key,
                lists: {
                    ...state.lists,
                    [action.key]: {
                        currentGroup: 0,
                        currentSubSegmentation: 0,
                        isLoading: false,
                        query: '',
                        extraFilters: action.extraFilters,
                        filters: [],
                        filtersCount: 0,
                        selected: [],
                        sortValue: [
                            {
                                property: 'firstName', direction: DirectionType.ASC,
                            },
                        ],
                        contacts: {
                            size: CONTACTS_SIZE_LIST,
                            totalNumber: 0,
                            startAt: 0,
                            requestId: '',
                            values: [],
                        },
                    },
                },
            });
        } else {
            patchState({
                currentList: action.key,
            });
        }
    }

    @Action(AddContactsToGroupsAction)
    addContactsToGroups({ setState, dispatch }: StateContext<ContactsListStateModel>, action: AddContactsToGroupsAction) {
        return this.contactGroups.addContactsByGroupsIds(action.groupsIds, action.contactsIds)
            .pipe(
                tap(() => {
                    if (action.clearSelected) {
                        setState(updateCurrentList({
                            selected: [],
                        }));
                    }
                    if (action.refreshGroups) {
                        dispatch(new GetGroupsAction());
                    }
                }),
            );
    }

    @Action(ApplySortValueAction)
    applySortValue({ setState }: StateContext<ContactsListStateModel>, action: ApplySortValueAction) {
        setState(updateCurrentList({ sortValue: action.sortValue }));
    }

    @Action(ChangeCurrentContactsListAction)
    changeCurrentContactsList({ patchState }: StateContext<ContactsListStateModel>, action: ChangeCurrentContactsListAction) {
        patchState({ currentList: action.key });
    }

    @Action(ChangeCurrentGroupAction)
    changeCurrentGroup({ setState }: StateContext<ContactsListStateModel>, action: ChangeCurrentGroupAction) {
        setState(updateCurrentList({
            currentGroup: action.id,
            selected: [],
        }));
    }

    @Action(ChangeSelectionAction)
    changeSelection({ getState, setState }: StateContext<ContactsListStateModel>, action: ChangeSelectionAction) {
        const state = getState();
        let cloneSelected = [...state.lists[state.currentList].selected];

        const index = cloneSelected.find((contactSelected) => contactSelected.id === action.contactId);

        if (!index) {
            cloneSelected.push({
                id: state.contacts[action.contactId].id,
                firstName: state.contacts[action.contactId].firstName,
                lastName: state.contacts[action.contactId].lastName,
            });
        } else {
            cloneSelected = cloneSelected.filter((contactSelected) => contactSelected.id !== action.contactId);
        }

        setState(updateCurrentList({
            selected: cloneSelected,
        }));
    }

    @Action(ChangeSubSegmentationAction)
    changeSubSegmentation({ setState }: StateContext<ContactsListStateModel>, action: ChangeSubSegmentationAction) {
        setState(updateCurrentList({
            currentSubSegmentation: action.subSegmentationId,
        }));
    }

    @Action(ClearContactsAction)
    clearContacts({ getState, patchState }: StateContext<ContactsListStateModel>, action: ClearContactsAction) {
        const state = getState();
        const rest = pick(action.contactsIds, state.contacts);

        patchState({ contacts: { ...rest } });
    }

    @Action(CreateGroupAction)
    createGroup({ getState, setState, patchState, dispatch }: StateContext<ContactsListStateModel>, action: CreateGroupAction) {
        return this.contactGroups.create(action.name)
            .pipe(
                switchMap((result) => (action.contactsIds.length ? this.contactGroups.addContactsByGroupId(result.id, action.contactsIds) : of(true))
                    .pipe(
                        tap(() => {
                            const state = getState();

                            patchState({
                                groups: {
                                    ...state.groups,
                                    [result.id]: {
                                        ...result,
                                        count: action.contactsIds.length,
                                    },
                                },
                            });

                            if (action.emptySelected) {
                                setState(updateCurrentList({ selected: [] }));
                            }

                            if (!action.mustStay) { dispatch(new ChangeCurrentGroupAction(result.id)); }
                        }),
                    )),
            );
    }

    @Action(DeleteContactsAction)
    deleteContacts(_ctx: StateContext<ContactsListStateModel>, action: DeleteContactsAction) {
        return this.contactsService.deleteContacts(action.contactIds);
    }

    @Action(DeleteGroupAction)
    deleteGroup({ getState, patchState, dispatch }: StateContext<ContactsListStateModel>, action: DeleteGroupAction) {
        return this.contactGroups.deleteGroupById(action.groupId)
            .pipe(
                tap(() => {
                    const state = getState();

                    patchState({
                        groups: Object.entries(state.groups).reduce((acc: { [key: string]: ContactGroup }, [key, value]) => {
                            if (parseInt(key, 10) === action.groupId) {
                                return acc;
                            }
                            return {
                                ...acc,
                                [key]: value,
                            };
                        }, {}),
                        lists: Object.entries(state.lists).reduce((acc: { [key: string]: ContactsListModel }, [key, value]) => ({
                            ...acc,
                            [key]: {
                                ...value,
                                currentGroup: action.groupId === value.currentGroup ? ENVESTBOARD_GROUP_ID : value.currentGroup,
                            },
                        }), {}),
                    });

                    if (action.groupId === state.lists[state.currentList].currentGroup) { dispatch(new ResetAndSearchAction()); }
                }),
            );
    }

    @Action(GetGroupsAction)
    getGroups({ patchState }: StateContext<ContactsListStateModel>) {
        return this.contactGroups.getAll()
            .pipe(
                tap((results) => {
                    patchState({
                        groups: indexBy(prop('id'), results.values),
                    });
                }),
            );
    }

    @Action(GetGroupAction)
    getGroup({ getState, patchState }: StateContext<ContactsListStateModel>, action: GetGroupAction) {
        const state = getState();
        return this.contactGroups.getGroupById(action.groupId).pipe(
            tap((result) => {
                patchState({
                    groups: {
                        ...state.groups,
                        [action.groupId]: {
                            ...result,
                        },
                    },
                });
            }),
        );
    }

    @Action(GetMetaDataAction)
    getMetaData({ patchState }: StateContext<ContactsListStateModel>, action: GetMetaDataAction) {
        return this.contactsService.getSearchMetaData(action.refreshCache)
            .pipe(
                tap((metaData) => {
                    patchState({
                        facets: metaData.facets,
                        subSegmentations: metaData.subSegmentations,
                    });
                }),
            );
    }

    @Action(RemoveContactsFromGroupsAction)
    removeContactsFromGroups({ setState, dispatch }: StateContext<ContactsListStateModel>, action: RemoveContactsFromGroupsAction) {
        return this.contactGroups.deleteContactsByGroupIds(action.groupsIds, action.contactsIds)
            .pipe(
                tap(() => {
                    if (action.clearSelected) {
                        setState(updateCurrentList({
                            selected: [],
                        }));
                    }
                    if (action.refreshGroups) {
                        dispatch(new GetGroupsAction());
                    }
                }),
            );
    }

    @Action(ResetAndSearchAction)
    resetAndSearch({ setState, dispatch }: StateContext<ContactsListStateModel>) {
        setState(updateCurrentList({
            contacts: {
                size: CONTACTS_SIZE_LIST,
                totalNumber: 0,
                startAt: 0,
                requestId: '',
                values: [],
            },
        }));

        dispatch(new SearchAction());
    }

    @Action(ClearSearchAction)
    clearSearch({ setState }: StateContext<ContactsListStateModel>) {
        setState(updateCurrentList({
            contacts: {
                size: CONTACTS_SIZE_LIST,
                totalNumber: 0,
                startAt: 0,
                requestId: '',
                values: [],
            },
        }));
    }

    @Action(SearchAction, { cancelUncompleted: true })
    search({ getState, patchState, setState }: StateContext<ContactsListStateModel>) {
        const currentState = getState();

        setState(isLoading());

        const { filters, extraFilters, contacts, sortValue } = currentState.lists[currentState.currentList];
        const includeMetadata = !contacts.requestId;

        const formatFilter = this.advancedFiltersUtilsService.formatFilters([...filters, ...(extraFilters ?? [])]);
        const fields = CONTACTS_SEARCH_FIELDS.join(',');

        return this.contactsService.search(searchQueries(null, currentState, formatFilter, fields, sortValue, includeMetadata))
            .pipe(
                tap((results) => {
                    const { metadata, ...rest } = results;
                    const state = getState();

                    patchState({
                        ...(includeMetadata ? {
                            facets: metadata.facets,
                            subSegmentations: metadata.subSegmentations,
                        } : {}),
                        contacts: mergeRight(indexBy(prop('id'), results.values), state.contacts) ?? {},
                    });

                    setState(updateCurrentList({
                        isLoading: false,
                        indicatorsIsLoading: results.values.reduce((acc, current) => ({
                            ...acc,
                            [current.id]: true,
                        }), {} as ({ [key: number]: boolean })),
                        contacts: {
                            ...rest,
                            startAt: rest.values[rest.values.length - 1] ? rest.values[rest.values.length - 1].id : 0,
                            values: [
                                ...state.lists[state.currentList].contacts.values,
                                ...rest.values.map((contact) => contact.id),
                            ],
                        },
                    }));
                }),
            );
    }

    @Action(SearchTextAction, { cancelUncompleted: true })
    searchText({ setState }: StateContext<ContactsListStateModel>, action: SearchTextAction) {
        setState(updateCurrentList({
            query: action.query,
        }));
    }

    @Action(SelectAllAction)
    selectAll({ getState, setState }: StateContext<ContactsListStateModel>) {
        const state = getState();

        const selectedIds = [
            ...state.lists[state.currentList].contacts.values,
        ];

        const selectedContacts = selectedIds.map((id) => ({
            id: state.contacts[id].id,
            firstName: state.contacts[id].firstName,
            lastName: state.contacts[id].lastName,
        }));

        setState(updateCurrentList({ selected: [...new Set(selectedContacts)] }));
    }

    @Action(SelectUnselectContactAction)
    selectUnselectContact({ getState, setState }: StateContext<ContactsListStateModel>, action: SelectUnselectContactAction) {
        const state = getState();

        let selected: ContactSelected[] = [
            ...state.lists[state.currentList].selected,
        ];

        const index = selected.find((contactSelected) => contactSelected.id === action.id);

        if (!index) {
            selected = [
                ...selected,
                {
                    id: state.contacts[action.id].id,
                    firstName: state.contacts[action.id].firstName,
                    lastName: state.contacts[action.id].lastName,
                },
            ];
        } else {
            selected = selected.filter((item) => item.id !== action.id);
        }

        setState(updateCurrentList({ selected }));
    }

    @Action(UpdateGroupAction)
    updateGroup({ getState, patchState }: StateContext<ContactsListStateModel>, action: UpdateGroupAction) {
        return this.contactGroups.update(action.id, action.name)
            .pipe(
                tap(() => {
                    const state = getState();

                    patchState({
                        groups: {
                            ...(state.groups || {}),
                            [action.id]: {
                                ...state.groups[action.id],
                                name: action.name,
                            },
                        },
                    });
                }),
            );
    }

    @Action(UnselectAllAction)
    unselectAll({ setState }: StateContext<ContactsListStateModel>) {
        setState(updateCurrentList({ selected: [] }));
    }

    @Action(SetFiltersAction)
    setFilters({ setState }: StateContext<ContactsListStateModel>, action: SetFiltersAction) {
        setState(updateCurrentList({
            filters: action.filters,
            filtersCount: action.filters.reduce((acc, filter: AdvancedFilterGeneric) => {
                if (filter.value.values) {
                    return filter.value.values.reduce((acc2) => acc2 + 1, acc);
                }
                if (filter.value.periods) {
                    return filter.value.periods.reduce((acc2) => acc2 + 1, acc);
                }
                if (filter.value.value !== undefined && filter.value.value !== null) {
                    return acc + 1;
                }
                if (filter.value.min || filter.value.max) {
                    return acc + 1;
                }
                return acc;
            }, 0),
        }));
    }
}
